import React, { Component, Suspense, lazy } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import _get from 'lodash.get'

const Demo = lazy(() => import('./Demo.jsx'))
const ElevatedExpectation = lazy(() => import('./ElevatedExpectation.jsx'))
const Exclusions = lazy(() => import('./Exclusions.jsx'))
const InclusionUser = lazy(() => import('./InclusionUser.jsx'))
const Profile = lazy(() => import('./Profile.jsx'))
const QualifiedQEWs = lazy(() => import('./QualifiedQEWs.jsx'))
const RoutesAdmin = lazy(() => import('./RoutesAdmin.jsx'))
const RoutesReports = lazy(() => import('./RoutesReports.jsx'))
import { emailDomain } from '../services/utility'
import Dashboard from './dashboard/Dashboard.jsx'
import fourOhFour from './404.jsx'
import GlobalMessage from '../containers/GlobalMessage.jsx'
import HelpDocs from './HelpDocs.jsx'
import LoadingOverlay from '../components/LoadingOverlay.jsx'
import Observation from './observation/Observation.jsx'
import ObservationHistory from './ObservationHistory.jsx'
import Response from './response/Response.jsx'
import ScrollToTop from '../components/ScrollToTop.jsx'
import SubNavigation from '../components/SubNavigation.jsx'
import UnauthorizedAccess from './UnauthorizedAccess.jsx'
import UserAccess from './access/UserAccess.jsx'
import InvalidDomain from '../containers/InvalidDomain.jsx'
import Rating from './Rating.jsx'

class Core extends Component {

  pageView(user) {
    ReactGA.set({ page: window.location.pathname })
    if (user.authenticated) {
      ReactGA.set({ userId: _get(user, 'profile.email') })
    }
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    const { user } = this.props
    this.pageView(user)
    if (user && user.profile && user.profile.email && emailDomain(user.profile.email) !== 'dteenergy.com') {
      // Show Invalid Domain if and only if the user is signed in with an email AND the email has an invalid domain
      return (
        <InvalidDomain />
      )
    }
    return (
      <div className='page-wrapper'>
        <ScrollToTop />
        <SubNavigation/>
        <GlobalMessage />
        {/* Ensure that if user is not an admin, they are redirected to /unauthorized to admin paths */}
        {!user.admin && (<Route path='/admin/*' render={() => <Redirect to='/unauthorized' />} />)}
        <Suspense fallback={<LoadingOverlay isVisible isFixed />}>
          <Switch>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/unauthorized' component={UnauthorizedAccess} />
            <Route exact path='/profile' component={Profile} />
            <Route path='/reports' component={RoutesReports} />
            <Route path='/admin' component={RoutesAdmin} />
            {/*
              It is important the the observation have a unique key based on the pathname and the queryparams (location.search)
              so that it will remount when the observation form changes or when the editId (in a queryparam changes).
              Otherwise it will carry over data between forms which, in the past, potentially led to overridden observations.
              */}
            <Route exact path='/observation/:slug' component={Observation} key={`${window.location.pathname}${window.location.search}`} />
            <Route exact path='/response/:id' component={Response} />
            <Route exact path='/search' component={ObservationHistory} />
            <Route exact path='/elevated-expectation' component={ElevatedExpectation} />
            <Route exact path='/user-access' component={UserAccess} />
            <Route exact path='/inclusions' component={InclusionUser} />
            <Route exact path='/exclusions' component={Exclusions} />
            <Route exact path='/qualified-qews' component={QualifiedQEWs} />
            <Route exact path='/demo' component={Demo} />
            <Route exact path='/help' component={HelpDocs} />
            <Route exact path='/rating' component={Rating}/>
            <Route component={fourOhFour} />
          </Switch>
        </Suspense>
        <div className='container container-bottom-space' />
      </div>
    )
  }
}

Core.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  const { user } = state
  return {
    user,
  }
}

export default connect(mapStateToProps)(Core)
