import React, { useState, useEffect, Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import _get from 'lodash.get'

import {ISO_DATE_FORMAT} from '../constants.js'

const NUMBER_OF_WEEKS = 6
const DAYS_IN_A_WEEK = 7
const VIEW_FORMAT = 'L'
/*
JDatePickerInternal is a controlled component; however, it has intermediary states where the display value of the input
needs to be temporarily different than this.props.value.

An example of this is when a user is manually entering a date "12/03/1999", as they type it in
the date will be incomplete and shouldn't yet be parsed. 
*/
export default class JDatePickerInternal extends Component{
  constructor(props){
    super(props)
    this.wrapperRef = React.createRef()
    this.state = {
      isModalOpen:false,
      // Note: If manualInputValOverride is not in use it must be explicitly `null`
      // because an empty string is a valid manualInputOverride.  Be sure to consider
      // this when using manualInputValOverride as a condition
      manualInputValOverride:null
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.selectDateOnCalendar = this.selectDateOnCalendar.bind(this)
  }

  componentDidMount(){
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  selectDateOnCalendar(date) {
    const {value, onChange} = this.props
    this.setState({
      manualInputValOverride:null,
        // Close modal on date select, otherwise it would use the stale closure'd value
      isModalOpen:false
    })
    // Ensure that onChange is not called consecutively more than once for the same value
    if(date !== value){
        onChange(date)
    }
  }
  handleClickOutside(event){
    const {isModalOpen} = this.state
    // This handleClickOutside listener is always active so long as this component is mounted,
    // so, if the model is open and a click occurs outside
    // close the modal.
    if (isModalOpen && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      // Close modal on click outside
      this.setState({isModalOpen:false})
    }
  }
  getDateValidErrorString(mDateInQuestion){
    const {minDate, maxDate} = this.props
    if(!mDateInQuestion.isValid()){
      return `The date ${mDateInQuestion._i} is invalid`
    }
    if(minDate && mDateInQuestion.isBefore(moment(minDate))){
      return `The date ${mDateInQuestion.format(VIEW_FORMAT)} is before the earliest allowable date of ${moment(minDate).format(VIEW_FORMAT)}`
    }
    if(maxDate && mDateInQuestion.isAfter(moment(maxDate))){
      return `The date ${mDateInQuestion.format(VIEW_FORMAT)} is before the earliest allowable date of ${moment(maxDate).format(VIEW_FORMAT)}`
    }
  }
  render(){
    const {
      value,
      onChange,
      onHoverDate,
      className,
      selectionHighlightStart,
      selectionHighlightEnd,
      hoverHighlightStart,
      hoverHighlightEnd,
      minDate,
      maxDate,
      colors,
      placeholderText = '',
      required = false,
      clearable,
      disabled = false
    } = this.props
    const {manualInputValOverride, isModalOpen} = this.state
    const _colors = Object.assign({
      chevron: '#555',
      nonCurrentMonthDate: '#aaa',
      selected: '#d0d0d0',
      selectedFontColor: undefined,
      highlighted: '#ddd',
      endCapDates: '#54c7f1',
      today: '#f7ca77',
      disabled: '#999',
    }, colors)
    const drillableProps = {
      selectionHighlightStart,
      selectionHighlightEnd,
      hoverHighlightStart,
      hoverHighlightEnd,
      minDate,
      maxDate,
      setSelectedDate: this.selectDateOnCalendar,
      onHoverDate: onHoverDate,
      colors: _colors
    }
    const displayDateValue =
      manualInputValOverride !== null
        ? manualInputValOverride
        : value ? moment(value).format(VIEW_FORMAT) : ''
    return <div ref={this.wrapperRef} className={className}>
      <div className='input-group'>
        <input className='form-control'
          id={this.props.name}
          name={this.props.name}
          type='text'
          data-cy={this.props.cy}
          placeholder={placeholderText}
          required={required}
          disabled={disabled}
          value={displayDateValue}
          onClick={() => {
            if(!disabled){
              this.setState({isModalOpen:true})
            }
          }}
          onKeyDown={evt => {
            if (evt.key === "Enter") {
              this.setState({isModalOpen:false, manualInputValOverride:null})
            }
            if (evt.key === "Tab") {
              this.setState({isModalOpen:false})
            }
          }}
          onBlur={() => {
            // Clear manualInputValOverride when input loses focus so that the displayed date is driven by 
            // this.props.value once again
            this.setState({manualInputValOverride:null})
          }}
          onChange={evt => {
            const value = _get(evt, 'target.value', null)
            if (value !== null) {
              this.setState({manualInputValOverride:value || null})
              const parsed = moment(value, VIEW_FORMAT)
              const errorString = this.getDateValidErrorString(parsed)
              if (!errorString) {
                onChange(parsed.format(ISO_DATE_FORMAT))
              }else if (clearable && value == ''){
                // Allow nulling the date if the user deletes the whole date string
                onChange(null)
              }
            }
          }}
          autoComplete="off"
        />

        <span className='input-group-addon pointer' onClick={() => {
          if(!disabled){
            this.setState({isModalOpen:true})
          }
        }}>
          <i className='fa fa-calendar' aria-hidden='true' />
        </span>
      </div>
      {isModalOpen && (
        <DatePickerModal
          drillableProps={drillableProps}
          selectedDate={manualInputValOverride || value || moment().format(ISO_DATE_FORMAT)}
        />
      )}
    </div>

  }
}
JDatePickerInternal.propTypes = {
  // The name attribute on the input
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onHoverDate: PropTypes.func.isRequired,
  clearable: PropTypes.bool, // if date can be nulled
}
function DatePickerModal(props) {
  const { selectedDate, drillableProps } = props
  const { maxDate, minDate, colors, onHoverDate } = drillableProps
  const [dates, setDates] = useState([])
  const [viewDate, setViewDate] = useState(selectedDate)
  useEffect(() => {
    // When selectedDate changes, update the viewDate
    if (selectedDate) {
      setViewDate(selectedDate)
    }
  }, [selectedDate])
  useEffect(() => {
    if(!moment(viewDate).isValid()){
        setDates([])
        return
    }
    if(minDate && maxDate && moment(minDate).isAfter(moment(maxDate))){
      // prevent infinite loop in the errant case were the minDate is set to before the maxDate
      return
    }
    // Prevent viewing after the max date
    if (maxDate && moment(viewDate).isAfter(moment(maxDate))) {
      // Clamp at max
      setViewDate(maxDate)
      return
    }
    // Prevent viewing before the min date
    if (minDate && moment(viewDate).isBefore(moment(minDate))) {
      // Clamp at min
      setViewDate(minDate)
      return
    }
    // Prep dates for rendering this page of the calendar
    // Start with the first date of the calendar page (probably from the previous month)
    const currentMonth = moment(viewDate).month()
    const workingDate = moment(viewDate).startOf('month').startOf('week')
    const dates = []
    for (let i = 0; i < DAYS_IN_A_WEEK * NUMBER_OF_WEEKS; i++) {
      dates.push({
        dayOfMonth: workingDate.date(),
        dateISO: workingDate.format(ISO_DATE_FORMAT),
        isCurrentMonth: workingDate.month() === currentMonth
      })
      workingDate.add(1, 'day')
    }
    setDates(dates)
  }, [viewDate, minDate, maxDate])
  const weekRows = []
  for (let i = 0; i < NUMBER_OF_WEEKS; i++) {
    const rowDates = dates.slice(DAYS_IN_A_WEEK * i, DAYS_IN_A_WEEK + DAYS_IN_A_WEEK * i)
    weekRows.push(
      <DateRow key={rowDates[0] ? rowDates[0].dateISO : i}
        {...drillableProps}
        numbers={rowDates}
        selectedDate={selectedDate}
      />
    )
  }

  const allowViewNextMonth = !maxDate || moment(viewDate).isBefore(maxDate, 'month')
  const allowViewPrevMonth = !minDate || moment(viewDate).isAfter(minDate, 'month')


  return <div className={classNames('JDateRange')} style={{
    position: 'absolute',
    // zIndex should be higher than form-control
    zIndex: 3
  }}>
    <div style={{
      fontSize: '16px',
      padding: '4px 8px'
    }}>
      {moment(viewDate).year() === moment().year() ? moment(viewDate).format('MMMM') : moment(viewDate).format('MMMM, YYYY')}
      <div className='pull-right'>
        <i className={classNames('date-button fa fa-chevron-left', { disabled: !allowViewPrevMonth })}
          style={{
            color: allowViewPrevMonth ? colors.chevron : colors.disabled
          }} aria-hidden='true'
          onClick={() => {
            if(allowViewPrevMonth){
              setViewDate(viewDate => moment(viewDate).subtract(1, 'month').format(ISO_DATE_FORMAT))
            }
          }} />
        <span className='date-button'
          onClick={() => {
            setViewDate(moment().format(ISO_DATE_FORMAT))
          }} >
          ·
          </span>
        <i className={classNames('date-button fa fa-chevron-right', { disabled: !allowViewNextMonth })}
          style={{
            color: allowViewNextMonth ? colors.chevron : colors.disabled
          }} aria-hidden='true'
          onClick={() => {
            if(allowViewNextMonth){
              setViewDate(viewDate => moment(viewDate).add(1, 'month').format(ISO_DATE_FORMAT))
            }
          }} />
      </div>
    </div>
    <table data-cy='calendar' style={{ tableLayout: 'fixed', textAlign: 'center' }}>
      <thead>
        <tr>
          <th>S</th>
          <th>M</th>
          <th>T</th>
          <th>W</th>
          <th>T</th>
          <th>F</th>
          <th>S</th>
        </tr>
      </thead>
      <tbody
        onMouseLeave={() => {
          onHoverDate(null)
        }}
      >
        {weekRows}
      </tbody>
    </table>
  </div>
}
function DateRow(props) {
  const { numbers, selectedDate, setSelectedDate, onHoverDate, selectionHighlightStart, selectionHighlightEnd, hoverHighlightStart, hoverHighlightEnd, minDate, maxDate, colors } = props
  // Get local time
  const today = moment().format(ISO_DATE_FORMAT)
  return <tr>
    {numbers.map(n => {
      const mDate = moment(n.dateISO)
      const isSelected = selectedDate === n.dateISO
      const hasSelectionHighlight = isSelected
        || n.dateISO === selectionHighlightStart
        || n.dateISO === selectionHighlightEnd
        // highlight if date is between both (and both exist)
        || ((selectionHighlightStart && mDate.isSameOrAfter(selectionHighlightStart, 'day')) && (selectionHighlightEnd && mDate.isSameOrBefore(selectionHighlightEnd, 'day')))

      const hasHoverHighlight = n.dateISO === hoverHighlightStart
        || n.dateISO === hoverHighlightEnd
        // highlight if date is between both (and both exist)
        || ((hoverHighlightStart && mDate.isSameOrAfter(hoverHighlightStart, 'day')) && (hoverHighlightEnd && mDate.isSameOrBefore(hoverHighlightEnd, 'day')))
      const isEndCapDate = n.dateISO === hoverHighlightStart || n.dateISO === hoverHighlightEnd
      const disabled = (minDate && mDate.isBefore(moment(minDate))) || (maxDate && mDate.isAfter(moment(maxDate)))
      return <td key={n.dateISO} className={classNames('JDateRange-date', { disabled })} style={{
        color:
          disabled
            ? colors.disabled
            : !n.isCurrentMonth
              ? colors.nonCurrentMonthDate
              : hasSelectionHighlight
                ? colors.selectedFontColor
                : undefined,
        // selection highlight takes priority over hover highlight
        backgroundColor:
          isEndCapDate
            ? colors.endCapDates
            : hasHoverHighlight
              ? colors.highlighted
              : hasSelectionHighlight
                ? colors.selected
                : today === n.dateISO
                  ? colors.today
                  : undefined,
      }}
        onClick={() => {
          if (!disabled) {
            setSelectedDate(n.dateISO)
          }
        }}
        onMouseEnter={() => {
          if (!disabled) {
            onHoverDate(n.dateISO)
          }
        }}
      >
        {n.dayOfMonth}
      </td>
    })}

  </tr>
}