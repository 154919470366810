import * as authTypes from '../actions/auth'
import * as generalTypes from '../actions/gather.js'

// These roles must match the role enum in the database
export const roles = {
  USER: 'User',
  REPORTER: 'Reporter',
  QEW_CONTROLLER: 'QEW Controller',
  SUPER_USER: 'Super User',
  ADMINISTRATOR: 'Administrator'
}

export const ANON_USER = {
  profile: {},
  compliance_required: false,
  token: null,
  authenticated: false
}
export function user (state = Object.assign({}, ANON_USER), action) {
  switch (action.type) {
    case authTypes.AUTH_LOGIN_USER: {
      const { user } = action
      return {
        error: undefined,
        admin: user.role === roles.ADMINISTRATOR,
        superUser: user.role === roles.SUPER_USER,
        QEWController: user.role === roles.QEW_CONTROLLER || undefined,
        reporter: user.role === roles.REPORTER,
        compliance_required: user.hierarchy ? user.hierarchy.compliance_required : false,
        is_manager: user.hierarchy ? user.hierarchy.is_manager : false,
        profile: user,
        authenticated: true
      }
    }
    case authTypes.AUTH_LOGOUT_USER: {
      const { error } = action
      return Object.assign({}, ANON_USER, {
        error
      })
    }
    case generalTypes.RECEIVE_UPDATE_REMINDER_EMAIL: {
      const { response } = action
      return Object.assign({}, state, {profile: response})
    }
    default:
      return state
  }
}


