import React from 'react'
import {SuperFormSelectAsyncGeneric} from './SelectAsync.jsx'


function SelectCategory (props) {
  return <SuperFormSelectAsyncGeneric {...props} endpoint="/categories" labelKey='name'/>
}

SelectCategory.defaultProps = {
  labelText: 'Observation Type',
  placeholder: 'Select the type of observation...'
}
export default SelectCategory
