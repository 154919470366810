import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import { cog } from '../../api/cognition'
import SelectAsync from './SelectAsync.jsx'


function SelectLocation (props) {
  const {value, onChange} = props
  const loadOptions = useCallback(input => {
    const promise = cog.getLocations().then(locations => {
      const notListedNotDTE = locations.filter(l => {
        const location = l.name.toLowerCase()
        return location.indexOf('not listed') !== -1 || location.indexOf('non dte') !== -1
      }).sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA.indexOf('not listed - other') !== -1) { return 1 }
        if (nameB.indexOf('not listed - other') !== -1) { return -1 }
        // Regular sorting
        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
        return 0
      })
      const normalLocations = locations.filter(l => {
        const location = l.name.toLowerCase()
        return location.indexOf('not listed') === -1 && location.indexOf('non dte') === -1
      }).sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        // Regular sorting
        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
        return 0
      })
      return normalLocations.concat(notListedNotDTE)
    })
    return promise.then(data => data.map(d => ({value: d.id, label: d.name}))
      .filter(l => l.label.toLowerCase().indexOf(input.toLowerCase()) !== -1))
  }, [])
  return <SelectAsync {...props}
    value={value}
    onChange={onChange}
    loadOptions={loadOptions} />
}

SelectLocation.defaultProps = {
  labelname: 'Location',
  placeholder: 'Location of the observation...'
}
SelectLocation.propTypes = {
  value:PropTypes.any,
  onChange:PropTypes.func.isRequired
}
export default SelectLocation
