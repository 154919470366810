import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  Link, NavLink, withRouter
} from 'react-router-dom'

import { cog } from '../api/cognition'
import OAuth from '../auth/oauth'
import Logo from '../components/Logo.jsx'

function Dropdown(props) {
  const { headerContent, id, navOpen, setNavOpen } = props
  const open = id === navOpen
  return <li className={classNames('dropdown', { open })}>
    <button className='navbar-interactable dropdown-toggle' aria-haspopup='true' aria-expanded={navOpen ? 'true' : 'false'}
      onClick={() => {
        setNavOpen(open ? '' : id)
      }}
    >
      {headerContent}&nbsp;
      {open 
        ? <i className='fa fa-chevron-up hidden-xs' aria-hidden='true' />
        : <i className='fa fa-chevron-down hidden-xs' aria-hidden='true' />}
      {open
        ? <i className='fa fa-chevron-up hidden-sm hidden-md hidden-lg pull-right' aria-hidden='true' />
        : <i className='fa fa-chevron-down hidden-sm hidden-md hidden-lg pull-right' aria-hidden='true' />}
    </button>
    <ul className='dropdown-menu'>
      {props.children}
    </ul>
  </li>
}


class TopNavigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navOpen: '',
      isHamburgerOpen: false,
      questionnaires: []
    }
    this.wrapperRef = React.createRef()
    this.collapse = this.collapse.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState){
    const { user } = this.props
    if(prevProps.user !== user){
      if (user.authenticated && !OAuth.invalid()) {
        cog.getQuestionnaires().then(questionnaires => {
          this.setState({ questionnaires })
        }).catch(_ => {
          const err = new Error('Could not retrieve questionnaires')
          console.error(err)
        })
      }
    }
    const {isHamburgerOpen} = this.state
    // Add or remove a class to the body which will hide <main/> content
    // when the hamburger is open.
    // This is necessary because fixed hamburger menus have a variety of
    // complex bugs on different device/browser combinations, and this
    // menthod supports a hamburger menu that is not fixed.  With the 
    // hamburger menu being a regular part of the DOM, it won't run into
    // the usual scrolling issues that a fixed menu would.  And since
    // usability is paramount for this application, this is the preferred
    // approach.
    if(prevState.isHamburgerOpen !== isHamburgerOpen){
      const hamburgerClassName = ' hamburgerOpen'
      if(isHamburgerOpen){
        document.body.className = document.body.className + hamburgerClassName
      }else{
        document.body.className = document.body.className.split(hamburgerClassName).join('')
      }
    }

  }


  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.collapse()
    }

  }
  collapse() {
    const {navOpen, isHamburgerOpen} = this.state
    if(navOpen !== '' || isHamburgerOpen) {
      this.setState({ navOpen: '', isHamburgerOpen: false })
    }
  }

  questionnairesMenu() {
    const { questionnaires, navOpen } = this.state
    const filteredObservations = questionnaires
      .filter(obs => obs.published)

    return (
      <Dropdown headerContent={'Create New'} id={'questionnaires'} navOpen={navOpen} setNavOpen={navOpen => this.setState({ navOpen })}>
        {filteredObservations.map(obs => {
          return (
            <li key={obs.id}>
              <Link to={`/observation/${obs.slug}`} onClick={this.collapse}>{obs.name}</Link>
            </li>
          )
        })}
        <li key={'underground-utility-strike'}>
          <a target="_blank" rel="noopener noreferrer" href={`https://dteenergy.sharepoint.com/sites/corpsafety/Lists/Underground_Utility_Strike/NewForm.aspx?Source=https%3A%2F%2Fdteenergy%2Esharepoint%2Ecom%2Fsites%2Fcorpsafety%2FLists%2FUnderground%5FUtility%5FStrike%2FAllItems%2Easpx&RootFolder=`}>Underground Utility Strike</a>
        </li>
        <li key={'miss-dig-audits'}>
          <a target="_blank" rel="noopener noreferrer" href={`https://forms.office.com/Pages/ResponsePage.aspx?id=_tVhjkl3dk6I7m2Hma6BQ9EFaBjBsI9BvTZSIZMIV_BUNlE1OTg3OElYR1VRNllDMEVCUUg3UjE0Ui4u`}>Miss Dig Audits</a>
        </li>
      </Dropdown>
    )
  }

  adminMenu() {
    const { navOpen } = this.state
    return (
      <Dropdown headerContent={'Admin'} id={'admin'} navOpen={navOpen} setNavOpen={navOpen => this.setState({ navOpen })}>
        <li><Link to='/exclusions' onClick={this.collapse}>
          <i className='fas fa-ban' aria-hidden='true' /> Exclusions
          </Link></li>
        <li><Link to='/admin/title-exclusions' onClick={this.collapse}>
          <i className='fas fa-user-minus' aria-hidden='true' /> Title Exclusions
          </Link></li>
        <li><Link to='/inclusions' onClick={this.collapse}>
          <i className='fas fa-user-check' aria-hidden='true' /> Inclusions
          </Link></li>
        <li><Link to='/admin/safety-user' onClick={this.collapse}>
          <i className='fas fa-hard-hat' aria-hidden='true' /> Safety Team
          </Link></li>
        <li><Link to='/admin/user-access' onClick={this.collapse}>
          <i className='fas fa-handshake' aria-hidden='true' /> Data Access
          </Link></li>
        <li><Link to='/admin/global-message' onClick={this.collapse}>
          <i className='fas fa-sticky-note' aria-hidden='true' /> Global Message
          </Link></li>
        <li><Link to='/admin/roles' onClick={this.collapse}>
          <i className='fas fa-lock' aria-hidden='true' /> Roles
          </Link></li>
        <li><Link to='/admin/privileges' onClick={this.collapse}>
          <i className='fas fa-lock' aria-hidden='true' /> Privileges
          </Link></li>
        <li><Link to='/admin/editQSQ' onClick={this.collapse}>
          <i className='fas fa-poll-h' aria-hidden='true' /> Edit Questionnaires
          </Link></li>
        <li><Link to='/admin/suppliers' onClick={this.collapse}>
          <i className='fas fa-box-open' aria-hidden='true' /> Suppliers
          </Link></li>
        <li><Link to='/reports/ORM' onClick={this.collapse}>
          <i className='fas fa-file-excel' aria-hidden='true' /> ORM
          </Link></li>
        <li><Link to='/admin/HierarchyExport' onClick={this.collapse}>
          <i className='fas fa-sitemap' aria-hidden='true' /> Hierarchy Export
          </Link></li>
        <li><Link to='/admin/advanced-settings' onClick={this.collapse}>
          <i className='fas fa-cogs' aria-hidden='true' /> Advanced Settings
          </Link></li>

      </Dropdown>
    )
  }

  render() {
    const { user } = this.props
    const { navOpen } = this.state
    let navMenu = (
      <div>
        <ul className='nav navbar-nav'>
          <li><NavLink exact to='/' className="navbar-interactable" onClick={this.collapse}>Dashboard</NavLink></li>
          {this.questionnairesMenu()}
          {user.admin && this.adminMenu()}
          <li><NavLink exact to='/reports' className="navbar-interactable" onClick={this.collapse}>Reports</NavLink></li>
          <li><NavLink exact to='/search' className="navbar-interactable" onClick={this.collapse}>Search</NavLink></li>
        </ul>
        <ul className='nav navbar-nav navbar-right'>
          <Dropdown navOpen={navOpen} id={'profile'} headerContent={<Fragment>
            <i className='fa fa-user-circle' aria-hidden='true' />
            <span className='hidden-sm'> {user.profile.display_name} </span>
            <span className='hidden-lg hidden-md hidden-xs'> {user.profile.given_name} </span>
          </Fragment>} setNavOpen={navOpen => this.setState({ navOpen })}>
            <li>
              <Link to='/profile' onClick={this.collapse}>
                <i className='fas fa-id-card' aria-hidden='true' /> Profile
                </Link>
            </li>
            <li>
              <Link to='/user-access' onClick={this.collapse}>
                <i className='fas fa-handshake' aria-hidden='true' /> Data Access
                </Link>
            </li>
            {(user.QEWController || user.admin) &&
              <li>
                <Link to='/qualified-qews' onClick={this.collapse}>
                  <i className='fas fa-user-check' aria-hidden='true' /> Qualified QEWs
                  </Link>
              </li>
            }
            {user.superUser &&
              <li>
                <Link to='/exclusions' onClick={this.collapse}>
                  <i className='fas fa-ban' aria-hidden='true' /> Exclusions
                  </Link>
              </li>
            }
            {user.superUser &&
              <li>
                <Link to='/inclusions' onClick={this.collapse}>
                  <i className='fas fa-user-check' aria-hidden='true' /> Inclusions
                  </Link>
              </li>
            }
            <li>
              <Link to='/elevated-expectation' onClick={this.collapse}>
                <i className='fas fa-check-square' aria-hidden='true' /> Compliance Expectation
                </Link>
            </li>
            <li>
              <Link to='/help'>
                <i className='fas fa-question' aria-hidden='true' /> Help
                </Link>
            </li>
            <li>
              <button className='fake-link' onClick={() => {
                this.collapse()
                OAuth.logout()
              }}>
                <i className='fas fa-sign-out-alt' aria-hidden='true' /> Logout
              </button>
            </li>
          </Dropdown>
        </ul>
      </div>
    )

    let hamburgerButton = (
      <button type='button'
        tabIndex="0"
        className='navbar-toggle collapsed'
        aria-expanded={this.state.isHamburgerOpen ? 'true' : 'false'}
        aria-controls='navbar'
        onClick={() => {
          this.setState(prev => {
            const nowIsOpen = !prev.isHamburgerOpen
            if(nowIsOpen){
              // Scroll to top to show hamburger menu
              window.scrollTo(0,0)
            }
            return { isHamburgerOpen: nowIsOpen}
          })
        }}
      >
        <span className='sr-only'>Toggle navigation</span>
        {this.state.isHamburgerOpen
          ? <div style={{
            lineHeight: '14px',
            height: '34px'
          }}>
            <i className='fa fa-times' />
          </div>
          : (
            <Fragment>
              <span className='icon-bar' />
              <span className='icon-bar' />
              <span className='icon-bar' />
            </Fragment>
          )}
      </button>
    )

    if (!user.authenticated) {
      navMenu = null
      hamburgerButton = null
    }

    return (
      <div ref={this.wrapperRef}>
        <nav className='navbar navbar-default navbar-fixed-top navbar-dte'>
          <div className='container'>
            <div className='navbar-header'>
              <Logo onClick={this.collapse}/>
              {hamburgerButton}
            </div>
            <div id='navbar'>
              {navMenu}
            </div>
          </div>
        </nav>
        <div id='hamburger-menu' className={classNames({open: this.state.isHamburgerOpen })}>
          {navMenu}
        </div>
      </div>
    )
  }
}

TopNavigation.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object
}

function mapStateToProps(state) {
  const { user } = state
  return {
    user
  }
}

export default withRouter(connect(mapStateToProps)(TopNavigation))
