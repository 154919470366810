import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

import '../../css/employeeInfo.css'

import SelectOrg from '../../components/inputs/SelectOrg.jsx'
import SelectLocation from '../../components/inputs/SelectLocation.jsx'
import SelectSublocation from '../../components/inputs/SelectSublocation.jsx'
import SelectPersons from '../../components/inputs/SelectPersons.jsx'
import SelectPersonsIntelligent from '../../components/inputs/SelectPersonsIntelligent.jsx'
import SelectCategory from '../../components/inputs/SelectCategory.jsx'
import SelectCompany from '../../components/inputs/SelectCompany.jsx'
import Select from '../../components/inputs/Select.jsx'
import TimeExact from '../../components/inputs/TimeExact.jsx'
import { CustomInput, Input } from '../../components/forms/SuperForm.jsx'

import { FormDateLimitContext } from '../../contexts.js'
import { ISO_DATE_FORMAT } from '../../constants.js'
import ButtonGroup from '../../components/ButtonGroup.jsx'

const moment = extendMoment(Moment)

class EmployeeInfo extends React.Component {
  constructor(props) {
    super(props)
    this.validateObserved = this.validateObserved.bind(this)
  }

  observedOptions(startDate, endDate) {
    const range = startDate === undefined && endDate === undefined ? moment.range(moment().subtract(7, 'days'), moment()) : moment.range(startDate, endDate)
    const rangeByDay = range.reverseBy('day')
    return Array.from(rangeByDay).map(date => {
      let postfix = ''
      if (moment().isSame(date, 'day')) {
        postfix = '(Today)'
      }
      return (
        { value: date.format(ISO_DATE_FORMAT), label: `${date.format('LL')} ${postfix}` }
      )
    })
  }

  validateObserved(values) {
    const { user, allowAnonymousObserved } = this.props
    values = values || []
    if (values.length === 0 && allowAnonymousObserved === false) {
      return 'Required'
    }
    if (values.length > 0) {
      const selfFound = values.find((v) => {
        return user.profile.azure_id === v.value
      })
      if (selfFound !== undefined) {
        return 'Self observation not allowed.'
      }
    }
  }

  render() {
    const {
      showObservationType, allowAnonymousObserver,
      allowAnonymousObserved, allowFreeFormObserved,
      freeFormSelected,
      observedLabelText,
      slug
    } = this.props
    const locationLabelText = slug === 'Storm' ? `Location of Observation*` : 'Location*'
    // NOTE: If a new filter is added, be sure to support user editing of that filter's previous value in Observation.jsx (see initialValues)
    return (
      <div className='row'>
        <div className='col-md-12'>
          {allowAnonymousObserver &&
            <div className='form-group'>
              <div className='checkbox'>
                <label>
                  <Input name='anonymous' type='checkbox' /> I would like to submit this
                observation <strong>anonymously</strong>.
                  <small className='anonymous-help-text'>(When checking this box, this observation will not count in the corporate Compliance metric.)</small>
                </label>
              </div>
            </div>
          }
          {freeFormSelected ? (
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='name'>Name</label>
                  <Input name='free_form_observed_name' type='text' className='form-control' id='name' placeholder='Employee, contractor, or company...' />
                  <span className='help-block help-block-sm-text'>
                    Leave blank for an anonymous observation.
                  </span>
                  <span className='help-block help-block-sm-text'>
                    <small>
                      <strong>Example:</strong> Acme Inc. - Dave Smith <strong>or</strong> John Smith
                    </small>
                  </span>
                </div>
              </div>
              <div className='col-md-6'>
                <CustomInput name='company' component={SelectCompany} isRequired={!!this.props.free_form_observed_name} helpText='If a company is not listed you can enter it in the "Name" input.' />
              </div>
            </div>
          ) : (
            slug == 'Home-Safety-Walk-Down' ? (
              <CustomInput disabled name='observed' labelText={observedLabelText} component={SelectPersons}
                validate={this.validateObserved} isRequired={!allowAnonymousObserved} />
            ) : (
              <CustomInput name='observed' labelText={observedLabelText} component={SelectPersons}
                validate={this.validateObserved} isRequired={!allowAnonymousObserved}
                helpText={allowAnonymousObserved ? 'Leave blank for an anonymous observation.' : null} />
            )
          )}
          {allowFreeFormObserved &&
            <div className='form-group'>
              <div className='checkbox'>
                <label>
                  <Input name='freeForm' type='checkbox' /> I'm observing a contractor
                  <strong> who does not have</strong> a DTE Energy provided badge.
                </label>
              </div>
            </div>
          }
        </div>
        <div className='col-md-6'>
          <CustomInput component={SelectOrg} name='businessUnit' labelText='Business Unit*' isRequired isMulti={false} />
        </div>
        {(slug === 'Storm') && (
          <div className='col-md-6'>
            <CustomInput component={SelectLocation} name='location_storm_reports' labelText={`Person's Reports to Location*`} isRequired />
          </div>
        )}
        <div className='col-md-6'>
          <CustomInput component={SelectLocation} name='location' labelText={locationLabelText} isRequired />
        </div>
        <div className='col-md-12'>
          <SelectSublocation name='sublocation' labelText='Locale' enforceGeneralToSpecific />
        </div>
        {(slug === 'office' || slug === 'field') && (
          <Fragment>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor={'equipment'}>Equipment</label>
                <Input id='equipment'
                  className='form-control'
                  name='equipment'
                  type='text'
                  placeholder={'Identify any Equipment being utilized during the observation.'}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <label htmlFor={'task'}>Task</label>
                <Input id='task'
                  className='form-control'
                  name='task'
                  type='text'
                  placeholder={'Which Task was being performed during the observation?'}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <CustomInput name='time_of_day' className='form-control' labelText='Time' component={Select} options={[
                { value: '', label: '' },
                { value: 'Morning', label: 'Morning' },
                { value: 'Afternoon', label: 'Afternoon' },
                { value: 'Night', label: 'Night' }
              ]}>
              </CustomInput>
            </div>
          </Fragment>

        )}
        <div className='col-md-6'>
          <div className='form-group'>
            <label htmlFor='observedOn'>Observed On</label>
            <FormDateLimitContext.Consumer>
              {({ startDate, endDate }) =>
                <CustomInput id='observedOn'
                  className='form-control'
                  name='date'
                  component={Select}
                  options={this.observedOptions(startDate, endDate)}
                />
              }
            </FormDateLimitContext.Consumer>
          </div>
        </div>
        {(slug === 'Storm') && (
          <div className='col-md-6'>
            <TimeExact name="time_exact" label="When*" type="text" />
          </div>
        )}
        {showObservationType &&
          <div className='col-md-6'>
            <SelectCategory name='type' labelText='Observation Type*' isRequired />
          </div>
        }
        {(slug === 'field') && (
          <div className='col-md-12'>
            <CustomInput name='was_set_conducted'
              component={ButtonGroup}
              buttons={[
                { name: 'Yes', key: 'yes' },
                { name: 'No', key: 'no' },
              ]}
              label='During this observation was a SET observation conducted?' />
            {this.props.was_set_conducted === 'yes'
              ? <CustomInput
                name='set_personnel'
                labelText={'Enter SET Personnel'}
                component={SelectPersonsIntelligent}
                isMulti={false} />
              : null}
          </div>
        )}
      </div>
    )
  }
}

EmployeeInfo.defaultProps = {
  allowAnonymousObserver: false,
  allowAnonymousObserved: false,
  allowFreeFormObserved: false,
  showObservationType: true,
  freeFormSelected: false
}

EmployeeInfo.propTypes = {
  user: PropTypes.object.isRequired,
  allowAnonymousObserver: PropTypes.bool,
  allowAnonymousObserved: PropTypes.bool,
  allowFreeFormObserved: PropTypes.bool,
  showObservationType: PropTypes.bool,
  freeFormSelected: PropTypes.bool,
  observedLabelText: PropTypes.string,
  slug: PropTypes.string, // Questionnaire slug
  free_form_observed_name: PropTypes.string
}

function mapStateToProps(state) {
  const { user } = state
  return {
    user
  }
}

export default connect(mapStateToProps)(EmployeeInfo)
